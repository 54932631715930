@import "base/variables";
@import "base/typography";
@import "components/dropdown";
@import "components/page-loader";
@import "components/settings";
@import "components/checkbox";
@import "components/widgets";
@import "components/record";

body {
    /* SurveyJS Creator V1 */
    --primary-color: #075da8;
    --secondary-color: #0b6bbe;
    --primary-text-color: #4a4a4a;
    --secondary-text-color: #a7a7a7;
    --inverted-text-color: #ffffff;
    --primary-hover-color: #6fe06f;
    --selection-border-color: #1ab394;
    --primary-icon-color: #3d4d5d;
    --primary-bg-color: #f8f8f8;
    --secondary-bg-color: #f4f4f4;
    --primary-border-color: #e7eaec;
    --secondary-border-color: #ddd;
    --error-color: #ed5565;
    --primary: #075da8 !important;
    /* --secondary: #00A1DF; */
    margin: 0;
    padding: 0;
    font-family: var(--sjs-font-family, var(--font-family));
}

.svc-creator {
    height: 100vh;
    width: 100vw;
}

.svc-creator .svc-toolbox,
svc-adaptive-toolbox,
.svc-toolbox__panel {
    background: var(--primary-color) !important;
}

.svc-toolbox__item-container .sv-svg-icon use,
.svc-toolbox__item-title {
    --sjs-general-forecolor-light: white;
}

.svc-toolbox__container .sv-action-bar-item__icon use {
    --sjs-general-forecolor-light: white;
}

.svc-tabbed-menu-item--selected {
    --primary: #00a1df !important;
}

.svc-btn .svc-text {
    display: flex;
    align-items: center;
    flex-grow: 1;
    justify-content: center;
    color: var(--sjs-primary-backcolor, var(--primary, #19b394));
}

.svc-tabbed-menu-item {
    padding: calc(2 * (var(--sjs-base-unit, var(--base-unit, 3px)))) calc(3 * (var(--sjs-base-unit, var(--base-unit, 5px))));
    height: calc(8 * (var(--sjs-base-unit, var(--base-unit, 5px))));
}

.svc-tabbed-menu {
    height: calc(6 * (var(--sjs-base-unit, var(--base-unit, 7px))));
}

.svc-side-bar__container-header {
    height: calc(8 * (var(--sjs-base-unit, var(--base-unit, 5.35px))));
    padding: 0 0 3px 0;
}

.svc-side-bar__container-actions {
    width: 100%;
    box-sizing: border-box;
    padding: calc(1.5 * (var(--sjs-base-unit, var(--base-unit, 0px)))) var(--sjs-base-unit, var(--base-unit, 8px));
}

.svc-side-bar__container-title {
    padding: calc(1.5 * (var(--sjs-base-unit, var(--base-unit, 2px)))) calc(2 * (var(--sjs-base-unit, var(--base-unit, 8px))));
}

.tab-seperator {
    position: relative;
}

.tab-seperator::before {
    background-color: var(--sjs-border-default, var(--border, #d6d6d6));
    position: absolute;
    content: "";
    width: 1px;
    height: calc(100% - 12px);
    left: 0;
    top: 6px;
}

.d-none {
    display: none;
}

.text-primary {
    color: var(--primary);
}

.svc-toolbox__category {
    .svc-toolbox__tool {
        &:last-child {
            margin-bottom: 10px;
        }
    }

    &.svc-toolbox__category--collapsed {
        .svc-toolbox__category-header {
            margin-bottom: 0;
        }

        .svc-toolbox__tool {
            &:last-child {
                margin-bottom: 0;
            }
        }
    }
}

.svc-toolbox__category-header {
    background: transparent;
    margin-bottom: 5px;
    height: auto;
    padding-top: calc(1.5 * (var(--sjs-base-unit, var(--base-unit, 10px))));
    padding-bottom: calc(1.5 * (var(--sjs-base-unit, var(--base-unit, 10px))));

    .svc-toolbox__category-header__button {
        fill: var(--sjs-general-forecolor-light, var(--foreground-light, white));
    }

    .svc-toolbox__category-header__controls {
        top: var(--sjs-base-unit, var(--base-unit, 14px));
    }
}

.svc-toolbox__category-title {
    color: var(--sjs-general-forecolor, var(--foreground, white));
    font-size: calc(1.5 * (var(--sjs-base-unit, var(--base-unit, 10px))));
}

.spg-question__title {
    color: var(--sjs-general-forecolor-light, var(--foreground-light, #5a5c69));
}

.btn-outline {
    color: var(--primary) !important;
    background: #fff;
    border: 1px solid var(--primary);
    transition: 0.3s all;
    border-radius: var(--sjs-corner-radius, 4px);

    button {
        height: auto !important;
        line-height: 1.75 !important;
        margin: 0 !important;
        padding: 0px 1rem !important;
        transition: 0.3s all;

        .sv-action-bar-item__title {
            color: var(--primary);
        }
        &:hover {
            background-color: var(--primary) !important;
            color: white !important;
        }
    }

    &:hover {
        background-color: var(--primary);
        color: white;

        button {
            background-color: var(--primary);

            .sv-action-bar-item__title {
                color: white;
            }
        }
    }
}

.delete-header-text {
    margin-bottom: 10px;
    margin-top: 10px;
    font-size: 24px;
}

.delete-info {
    font-size: 13px;
    background: #e3eef6;
    padding: 10px;
    text-align: left;
    max-width: 450px;
    margin: auto;
    border-radius: 4px;
    display: flex;
    gap: 10px;

    i {
        color: #3c7cab;
        margin-top: 5px;
        font-size: 25px;
    }
}

div:where(.swal2-container) button:where(.swal2-styled).swal2-confirm {
    background-color: var(--primary) !important;
}
.swal2-confirm.custom-delete-confirm-btn {
    background-color: #d3494e !important; /* Danger color */
    color: #fff !important; /* White text */
    border-color: #d3494e !important; /* Border color */
}

// .btn {
//     display: inline-block;
//     font-weight: 400;
//     color: #212529;
//     text-align: center;
//     vertical-align: middle;
//     cursor: pointer;
//     -webkit-user-select: none;
//     -moz-user-select: none;
//     -ms-user-select: none;
//     user-select: none;
//     background-color: transparent;
//     border: 1px solid transparent;
//     padding: 0.375rem 0.75rem;
//     font-size: 1rem;
//     line-height: 1.5;
//     border-radius: 0.25rem;
//     transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
// }

.btn-outline-secondary {
    color: var(--sjs-general-forecolor-light, var(--foreground-light, #909090)) !important;
    background: #fff;
    border: 0px solid var(--sjs-general-forecolor-light, var(--foreground-light, #909090));
    transition: 0.3s all;
    border-radius: var(--sjs-corner-radius, 4px);
    margin-right: 0.5rem;

    button {
        height: auto !important;
        line-height: 1.75 !important;
        margin-top:4px !important;
        padding: 0.20rem;
        transition: .3s all !important;
        background-color: #28a745 !important;
        border:0px!important;
        

        .sv-action-bar-item__title {
            color: var(--sjs-general-forecolor-light, var(--foreground-light, #909090));
        }
        // &:hover{
        //     background-color: var(--sjs-general-forecolor-light,var(--foreground-light,#909090)) !important;
        //     color: white !important;
        // }
    }

    &:hover {
        background-color: var(--sjs-general-forecolor-light, var(--foreground-light, #909090));
        color: white;

        button {
            background-color: var(--sjs-general-forecolor-light, var(--foreground-light, #909090)) !important;
            color: white !important;
            .sv-action-bar-item__title {
                color: white;
            }
            .sv-action-bar-item__icon use {
                fill: white;
            }
        }
    }
}

.btn-outline-secondary.active {
    color: white !important;
    background: #28a745;
    border: 0px solid var(--primary-hover-color) !important;
    .sv-action-bar-item__icon use {
        fill: white;
    }
    &:hover {
        background-color: #28a745;
        color: white;

        button {
            background-color: #28a745 !important;
            color: white !important;
            .sv-action-bar-item__title {
                color: white;
            }
            .sv-action-bar-item__icon use {
                fill: white;
            }
        }
    }
}
.is-disabled {
    pointer-events: none;
    opacity: 0.7;
}
#integration-loader {
    margin-top: 30%;
    margin-left: 64%;
}

[data-key="links0"] {
    #add-item,
    #property-grid-setup,
    #property-grid-clear,
    #remove-row {
        display: none;
    }
}

.info-text {
    font-size: 0.875rem;
    color: black; /* Informative color */
    margin-top: 0.25rem;
}

[data-name="lookupdisclaimer"] input {
    display: none;
}
[data-name="lookupdisclaimer"] .spg-question__header .sv-string-viewer {
    color: var(--error-color);
}

.svc-toolbox {
    .svc-toolbox__search-container,
    .spg-search-editor_container {
        background-color: var(--primary-color);
        // --sjs-general-forecolor-light: white;
        color: white !important;

        .spg-search-editor_search-icon .sv-svg-icon use {
            --sjs-general-forecolor-light: white;
        }

        .spg-search-editor_input {
            color: white !important;
        }
        .spg-search-editor_input::placeholder {
            color: white !important;
        }

        #svd-grid-search-close {
            display: none; // because clicking close btn was giving error(surveyjs bug)
        }
    }
    .svc-toolbox__scroller {
        scrollbar-gutter: stable;
    }
}

.svc-logic-tab__content.svc-logic-tab--empty .svc-logic-tab__content-action {
    .svc-text {
        height: calc(7 * (var(--sjs-base-unit, var(--base-unit, 8px)))) !important;
    }
}

.svd-simulator-main.svd-simulator-main--desktop {
    height: 100%;
}
.pd-margin {
    margin-top: 10px !important;
}

.sd-input.sd-input--readonly{
    pointer-events: none;
}

.svc-toolbox__item-submenu-button{
    display: none;
}
.pd-margin{
    margin-top:20px !important;
    display:block;
  }

  .sd-row__question {
    word-wrap: break-word;   
    overflow-wrap: break-word;
  }